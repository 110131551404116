import React from "react";
import {
  Box,
  Flex,
  Link,
  Text,
  IconButton,
  Stack,
  useDisclosure,
  Collapse,
  useColorMode,
  useTheme,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  SunIcon,
  MoonIcon,
} from "@chakra-ui/icons";

function HomeNavbar() {
  const { isOpen, onToggle } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  const theme = useTheme();
  return (
    <Box
      id="HomeNavbar"
      bg={colorMode === "dark" ? theme.colors.dark.bg : theme.colors.light.bg}
      color={colorMode === "dark" ? theme.colors.dark.color : theme.colors.light.color}
      as="nav"
      className="container"
      boxShadow="sm"
    >
      {/* Main Flex Container */}
      <Flex w="100%" justify="space-between" align="center">
        {/* Logo */}
        <Link
          href="/"
          fontSize="2xl"
          fontWeight="bold"
          p={4}
          ml="4"
          _hover={{ textDecoration: "none" }}
        >
          <Text
            as="span"
            fontFamily="sans-serif"
            display="inline"
          >
            Very Good
            <Text
              as="span"
              pl="2"
              color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
            >
              Trip
            </Text>
          </Text>
        </Link>

        {/* IconButton visible only on mobile */}
        <Flex alignItems="center" display={{ base: "flex", lg: "none" }}>
          <IconButton
            icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            onClick={toggleColorMode}
            aria-label={"Toggle Color Mode"}
            borderRadius="20px"
            boxShadow="md"  // Optional: Add a shadow to enhance the visibility
            bg={theme.colors.light_secondary.bg}
            color={theme.colors.light_bg.black
            }
            _hover={{
              bg: theme.colors.light_bg.middlegrey,
            }}
          />
          <IconButton
            aria-label="Toggle navigation"
            icon={<HamburgerIcon />}
            display={{ base: "block", lg: "none" }}
            onClick={onToggle}
            m={4}
            bg={theme.colors.light_secondary.bg}
            color={theme.colors.light_secondary.color}
            _hover={{
              bg: theme.colors.light_bg.middlegrey,
            }}
          />
        </Flex>

        {/* Desktop Menu */}
        <Flex
          align="center"
          justify="center"
          gap={4}
          display={{ base: "none", lg: "flex" }}
        >


          <Link
            href="/travels"
            position="relative"
            fontSize="xl"
            fontWeight="normal"
            className="nav-link"
            _hover={{
              textDecoration: "none",
            }}
          >
            Mes voyages
          </Link>
        </Flex>
        {/* Additional Icons for Desktop */}
        <Flex
          align="center"
          justify="center"
          gap={2}
          display={{ base: "none", lg: "flex" }}
        >
          <Box display="flex"
            alignItems="center"
            mr={2}
          >
            <Box
              display="flex"
              alignItems="center"
              mx={2}
              p={4}
            >
              <IconButton
                icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                onClick={toggleColorMode}
                aria-label={"Toggle Color Mode"}
                borderRadius="20px"
                boxShadow="md"  // Optional: Add a shadow to enhance the visibility
                bg={theme.colors.light_bg.white
                }
                color={theme.colors.light_bg.black
                }
                _hover={{
                  bg: theme.colors.light_bg.middlegrey,
                }}
              />
            </Box>
          </Box>
        </Flex>
      </Flex>

      {/* Mobile Menu */}
      <Collapse in={isOpen} transition={{ enter: { duration: 0 }, exit: { duration: 0 } }} >
        <Stack
          position="absolute"
          top="70px"
          zIndex="5"
          h="auto"
          direction="column"
          spacing={4}
          display={{ base: "flex", lg: "none" }}
          align="start"
          w="100%"
          p={4}
          boxShadow="md"
          bg={
            colorMode === "dark"
              ? theme.colors.dark_secondary.bg
              : theme.colors.light_secondary.bg
          }
          color={
            colorMode === "dark"
              ? theme.colors.dark_secondary.color
              : theme.colors.light_secondary.color
          }
        >
          {/* Dropdown Menu for Mobile */}

          <Link
            href="/travels"
            fontWeight="600"
            fontSize="lg"
            className="nav-link"
            ml="4"
            color={
              colorMode === "dark"
                ? theme.colors.dark.bg
                : theme.colors.light.bg
            }
            _hover={{
              textDecoration: "none",
              color: colorMode === "dark"
                ? theme.colors.dark_tertiary.bg
                : theme.colors.light_tertiary.bg
            }}
          >
            Mes voyages
          </Link>
        </Stack>
      </Collapse>
    </Box>
  );
}

export default HomeNavbar;