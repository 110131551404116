import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { AppProvider } from './context/AppContext';
import TravelHome from './components/pages/TravelHome';
import TravelPrograms from './components/pages/TravelPrograms';
import theme from './theme';

function App() {
  return (
    <AppProvider>
      <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <Router>
            <Routes>
              <Route path="/" element={<TravelHome/>}/>
              <Route path="/travels" element={<TravelPrograms/>}/>
            </Routes>
        </Router>
      </ChakraProvider>
    </AppProvider>
  );
}

export default App;
