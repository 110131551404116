import React, { createContext, useContext, useReducer, useEffect } from 'react';

const initialState = {
  searches: JSON.parse(localStorage.getItem('searches')) || [], // Charger les recherches depuis le localStorage
  currentResult: null,  // Stocke le résultat actuel pour l'affichage immédiat
  isLoading: false,  // Indicateur de chargement
  error: null,  // Stocke les erreurs éventuelles
};

// Création du contexte pour partager l'état global à travers l'application
const AppContext = createContext();

// Réducteur pour gérer les différentes actions sur l'état global
const appReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_SEARCH':
      // Ajouter ou mettre à jour une recherche dans `searches`
      return {
        ...state,
        searches: [...state.searches.filter(search => search.id !== action.payload.id), action.payload],
      };
    case 'SET_CURRENT_RESULT':
      // Mettre à jour le résultat courant et arrêter le chargement
      return {
        ...state,
        currentResult: action.payload,
        isLoading: false,
      };
    case 'SET_LOADING':
      // Définir l'état de chargement à `true`
      return { ...state, isLoading: true };
    case 'SET_ERROR':
      // Stocker l'erreur et arrêter le chargement
      return { ...state, error: action.payload, isLoading: false };
      case 'SET_ACTIVE_SEARCH':
        // Mettre à jour l'ID de la recherche active
        return { ...state, activeSearchId: action.payload };
      default:
        return state;
  }
};

// Fournisseur de contexte pour encapsuler l'application et partager l'état global
export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  // Sauvegarder les recherches dans le localStorage à chaque mise à jour de `searches`
  useEffect(() => {
    localStorage.setItem('searches', JSON.stringify(state.searches));
  }, [state.searches]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

// Hook personnalisé pour accéder facilement au contexte dans les composants
export const useAppContext = () => useContext(AppContext);