'use client'
import React from 'react'
import { useState } from 'react'
import {
  Progress,
  Box,
  ButtonGroup,
  Button,
  Flex,
  useToast,
  useColorMode,
  useTheme
} from '@chakra-ui/react'

const MultistepForms = ({ steps, onSubmit }) => {

  const { colorMode } = useColorMode();
  const theme = useTheme();

  const toast = useToast()
  const [step, setStep] = useState(0)
  const [formData, setFormData] = useState({})

  // Vérifiez que les étapes sont définies
  if (!steps || steps.length === 0) {
    return <div>No steps provided</div>
  }

  const currentStep = steps[step]
  const progress = ((step) / steps.length) * 100

  const handleChange = (field, value) => {
    setFormData(prevData => ({ ...prevData, [field]: value }))
  }

  const handleNext = () => {
    if (step < steps.length - 1) {
      setStep(step + 1)
    } else {
      // Execute the onSubmit function with collected form data
      onSubmit(formData)
        .then(() => {
          toast({
            title: 'Form submitted.',
            description: "Your form has been submitted successfully.",
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        })
        .catch(error => {
          toast({
            title: 'Submission failed.',
            description: error.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        })
    }
  }

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1)
    }
  }

  return (
    <Box
      borderWidth="1px"
      rounded="lg"
      bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg}
      color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
      shadow="1px 1px 10px rgba(0,0,0,0.3)"
      maxWidth={700}
      p={6}
      m="0px auto"
      as="form">
      <Progress hasStripe value={progress}
        mb="5%"
        mx="5%"
        borderRadius="5px"
        colorScheme="teal"  // Change the color of the progress bar
        isAnimated />
      {currentStep ? React.cloneElement(currentStep, { formData, handleChange }) : null}
      <ButtonGroup mt="5%" w="100%">
        <Flex w="100%" justifyContent="space-between">
          <Button
            onClick={handleBack}
            isDisabled={step === 0}
            colorScheme="teal"
            variant="solid"
            w="7rem"
            mr="5%"
            bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
            color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
            _hover={{
              backgroundColor: colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg,
              color: colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color,
            }}
          >
            Back
          </Button>
          <Button
            w="7rem"
            onClick={handleNext}
            colorScheme="teal"
            bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
            color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
            _hover={{
              backgroundColor: colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg,
              color: colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color,
            }}
          >
            {step === steps.length - 1 ? 'Submit' : 'Next'}
          </Button>
        </Flex>
      </ButtonGroup>
    </Box>
  )
}

export default MultistepForms
