import React from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { useAppContext } from "../../context/AppContext";
import MultistepForms from "../forms/MultiStepForms";
import TravelDestinationAndDate from "../forms/TravelDestinationAndDate";
import TravelPreferenceForm from "../forms/TravelPreferenceForm";

function MultistepFormsExample() {
  const navigate = useNavigate();
  const { dispatch } = useAppContext();


  const handleFormSubmit = async (data) => {

    const searchId = uuidv4();
    const searchData = { ...data, id: searchId, timestamp: new Date() };

    // Ajouter la recherche dans le contexte global
    dispatch({ type: 'ADD_SEARCH', payload: searchData });

    // Redirect immediately to the results page
    navigate('/travels', { state: { searchId, isLoading: true } });
  };

  const steps = [
    <TravelDestinationAndDate key="step1" />,
    <TravelPreferenceForm key="step2" />,
  ];

  return (
    <div>
      <MultistepForms steps={steps} onSubmit={handleFormSubmit} />
    </div>
  );
}

export default MultistepFormsExample;
