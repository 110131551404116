import React from 'react';
import { Box, Text, useColorMode, useTheme, keyframes } from '@chakra-ui/react';
import { HashLoader } from 'react-spinners';


// Animation keyframes
const revealAnimationVeryGoodTrip = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  33% {
    opacity: 1;
    transform: translateX(0)
  }
  66% {
    opacity: 1;
    transform: translateX(0)
  }
  100% {
    opacity: 0;
    transform: translateX(+50%);
  }
`;

// Loader Component
const ModernLoader = () => {
  const { colorMode } = useColorMode();
  const theme = useTheme();

  const loaderColor = colorMode === 'dark'
    ? theme.colors.dark_tertiary.bg
    : theme.colors.light_tertiary.bg;

  return (
    <Box
      position="relative"
      zIndex={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >

      {/* Custom Spinner */}
      <Box position="relative" display="flex" justifyContent="center" width="80px" height="auto" mx="auto">
        <HashLoader color={loaderColor} speedMultiplier={0.4} size={100} mb={2} />
      </Box>

      {/* Text Animation */}
      <Text
        as="h2"
        color={theme.colors.light_tertiary.color}
        fontSize={{ base: "50px", md: "100px" }}
        textShadow="2px 2px 20px rgba(0, 0, 0, 0.5)"
        fontFamily="sans-serif"
        display="inline"
        animation={`${revealAnimationVeryGoodTrip} 5s ease forwards infinite`}
        mb={2}
      >
        Very Good
        <Text
          as="span"
          pl="3"
          color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
        >
          Trip
        </Text>
      </Text>

      {/* Loading text */}
      <Text mt={4} textAlign="center" fontSize={{ base: "xl", md: "3xl" }} textShadow="2px 2px 30px rgba(0, 0, 0, 0.5)">
        Création de votre voyage...
      </Text>

      {/* Keyframes for spinning animation */}
      <style>
        {`
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </Box>
  );
};

export default ModernLoader;
