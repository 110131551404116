import { useEffect } from 'react';
import { useAppContext } from '../context/AppContext';

const useLoadResult = (searchId) => {
  const { state, dispatch } = useAppContext();

  useEffect(() => {
    
    
    const loadResult = async () => {
      // Trouver la recherche correspondante par ID dans les recherches sauvegardées
      const search = state.searches.find(s => s.id === searchId);
      if (!search) {
        // Si la recherche n'existe pas, définir une erreur et arrêter l'exécution
        console.error(`Search with id ${searchId} not found.`);
        dispatch({ type: 'SET_ERROR', payload: 'Search not found.' });
        return;
      }
      if (search.result) {
        // Si un résultat existe déjà pour cette recherche, l'utiliser et ne pas relancer la recherche
        dispatch({ type: 'SET_CURRENT_RESULT', payload: search.result });
        return;
      }

      // Si aucun résultat n'existe, lancer le processus de chargement
      dispatch({ type: 'SET_LOADING' });

      try {
        // Faire l'appel API pour obtenir les résultats du voyage
        const response = await fetch('https://backend-very-good-trip-5ny6k2c6oq-ew.a.run.app/trip_planner', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(search),
        });

        const result = await response.json();

        // Mise à jour du contexte avec le résultat récupéré
        dispatch({ type: 'SET_CURRENT_RESULT', payload: result });

        // Mettre à jour `searches` dans l'état global avec le nouveau résultat
        const updatedSearches = state.searches.map(s =>
          s.id === searchId ? { ...s, result } : s
        );

        // Sauvegarder la mise à jour dans le `localStorage`
        localStorage.setItem('searches', JSON.stringify(updatedSearches));

        // Mettre à jour `state.searches` dans le contexte pour éviter de re-déclencher `useEffect`
        dispatch({ type: 'ADD_SEARCH', payload: { ...search, result } });

      } catch (error) {
        // En cas d'erreur, l'enregistrer dans l'état global
        dispatch({ type: 'SET_ERROR', payload: error.message });
      }
    };

    // Exécuter `loadResult` seulement si `searchId` est défini et qu'il n'y a pas déjà un résultat
    if (searchId) {
      loadResult();
    }
  }, [searchId, dispatch, state.searches]); // Limiter les dépendances à `searchId` uniquement

};

export default useLoadResult;
