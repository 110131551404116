import React from "react";
import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  Text,
  Image,
  Stack,
  useColorMode,
  useTheme
} from "@chakra-ui/react";

function NewsletterSignup() {
  const { colorMode } = useColorMode();
  const theme = useTheme();
  return (
    <Box
      bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg}
      color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
      pt={28}
      pb={32}
      px={12}
      maxW="100%"
      mx="auto"
      textAlign="center"
      display="flex"
      justifyContent="center"
      boxShadow="md"
    >
      <Stack spacing={5} maxW="1200px">
        {/* Image */}
        <Image
          src="./img/home/home-travel-detoure.png"
          objectFit="cover"
          alt="Newsletter Icon"
          boxSize="250px"
          mx="auto"
          mb={4}
        />

        {/* Heading */}
        <Heading as="h2" size="lg" mb={2}>
          S'abonner à notre newsletter
        </Heading>

        {/* Subheading */}
        <Text fontSize="lg" mb={4}>
          Vous serez les premiers à recevoir nos meilleures offres, nos promotions exclusives et nos conseils de voyage. De plus, nous vous tiendrons informé des endroits où vous pouvez voyager.
        </Text>

        {/* Email Input and Button */}
        <Flex
          as="form"
          align="center"
          justify="center"
          direction={{ base: "column", md: "row" }}
          mb={4}
        >
          <Input
            type="email"
            placeholder="Votre adresse e-mail"
            size="lg"
            mb={{ base: 3, md: 0 }}
            mr={{ md: 2 }}
            w={{ base: "100%", md: "auto" }}
            borderColor="gray.300"
          />
          <Button
            size="lg"
            colorScheme="gray"
            bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
            color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
            px={8}
            _hover={{
              backgroundColor: colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg,
              color: colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color,
            }}
          >
            S'abonner
          </Button>
        </Flex>

        {/* Footer Text */}
        <Text fontSize="sm">
          En saisissant votre adresse e-mail, vous acceptez de recevoir des propositions commerciales personnalisées générées automatiquement, y compris des propositions et offres de produits et services de notre part et de tiers sélectionnés (vos données ne seront pas partagées avec ces tiers). Pour plus d'informations ou pour révoquer votre consentement, consultez notre <Text as="span" color="blue.500" cursor="pointer" textDecoration="underline">Politique de confidentialité</Text> (vous pouvez également vous désinscrire en cliquant sur le lien dans l'e-mail).
        </Text>
      </Stack>
    </Box>
  );
}

export default NewsletterSignup;
