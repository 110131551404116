import { Box, VStack, Text, useColorMode, useTheme } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';

const Sidebar = ({ searches }) => {
  const { colorMode } = useColorMode();
  const theme = useTheme();
  const navigate = useNavigate();
  const { state, dispatch } = useAppContext(); // Utiliser dispatch ici

  const handleSearchClick = (search) => {
    dispatch({ type: 'SET_ACTIVE_SEARCH', payload: search.id }); // Met à jour l'état actif avec l'ID du clic
    navigate('/travels', { state: { searchId: search.id, isLoading: !search.result } });
  };

  return (
    <Box
      bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white}
      color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
      p={4}
    >
      <VStack align='start' spacing={1}>
        {state.searches.map((search) => (
          <Text
            key={search.id}
            fontSize={{ sm: 'sm', md: 'md' }}
            onClick={() => handleSearchClick(search)}
            cursor="pointer"
            w="auto"
            h="100%"
            px={4}
            py={2}
            borderRadius="20px"
            transition=".3s"
            bg={state.activeSearchId === search.id ? (colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg) : 'transparent'}
            color={state.activeSearchId === search.id ? (colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color) : 'inherit'}
            _hover={{
              bg: colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg,
              color: colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color,
            }}
          >
            {search.departure} <ArrowForwardIcon /> {search.destination}
          </Text>
        ))}
      </VStack>
    </Box>
  );
};

export default Sidebar;
