'use client'

import {
  Box,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  useColorMode,
  useTheme
} from '@chakra-ui/react'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';


const TravelDestinationAndDate = ({ formData, handleChange }) => {

  const { colorMode } = useColorMode();
  const theme = useTheme();


  const selectionRange = {
    startDate: formData.startDate || new Date(),
    endDate: formData.endDate || new Date(),
    key: 'selection',
  };

  const handleSelect = (ranges) => {
    const { selection } = ranges;
    handleChange('startDate', selection.startDate);
    handleChange('endDate', selection.endDate);
  };

  return (
    <>
      <Heading w="100%" textAlign={'center'} fontWeight="normal" mb="20px">
        Où Allons nous ?
      </Heading>
      <Flex direction={{ base: 'column', md: 'row' }}>
        <FormControl mr="5%">
          <FormLabel htmlFor="departure" fontWeight={'normal'}>
            Départ
          </FormLabel>
          <Input
            bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white}
            color={colorMode === 'dark' ? theme.colors.dark_bg.white : theme.colors.light_bg.black}
            id="departure"
            placeholder="Ville de départ"
            value={formData.departure || ''}
            onChange={(e) => handleChange('departure', e.target.value)}
          />
        </FormControl>

        <FormControl mt={{ base: "20px", md: "0" }}>
          <FormLabel htmlFor="destination" fontWeight={'normal'}>
            Destination
          </FormLabel>
          <Input
            bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white}
            color={colorMode === 'dark' ? theme.colors.dark_bg.white : theme.colors.light_bg.black}
            id="destination"
            placeholder="Pays, région ou ville de destination"
            value={formData.destination || ''}
            onChange={(e) => handleChange('destination', e.target.value)}
          />
        </FormControl>
      </Flex>


      <FormControl>
        <FormLabel textAlign="center" htmlFor="password" fontWeight={'normal'} mt="20px">
          Date
        </FormLabel>
        <InputGroup size="md" display="flex"
          justifyContent="center"
        >
          <Flex borderRadius="10"
          >
            <Box className="custom-date-range">
              <DateRangePicker
                mx="auto"
                ranges={[selectionRange]}
                onChange={handleSelect}
                editableDateInputs={true}
                showSelectionPreview={false}
              />
            </Box>
            <style>
              {`
                  .custom-date-range .rdrCalendarWrapper {
                    background-color: ${colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white} !important;
                    color: ${colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color} !important;
                  }
                  
                  .rdrDayNumber span,
                  .rdrMonthAndYearWrapper .rdrMonthAndYearPickers select {
                    color: ${colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color};
                  }

                  .custom-date-range .rdrDateDisplayWrapper {
                    background-color: ${colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.darkgrey};
                    color: ${colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color};
                  }
                  
                  .rdrDay .rdrStartEdge,
                  .rdrDay .rdrInRange,
                  .rdrDay .rdrEndEdge {
                    background-color: ${colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg}; !important;
                    color: ${colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color}; !important;
                  }

                  .rdrDefinedRangesWrapper {
                    display: none !important;
                    }
                    .rdrCalendarWrapper { border-radius: 10px !important;}
                    .rdrDateDisplayWrapper { border-radius: 10px 10px 0 0 !important;}
                    
                    @media (max-width: 600px) {
                      .custom-date-range .rdrCalendarWrapper {
                        max-width: 90vw !important;
                        transform-origin: top center;
                      }
                    }
                      
                  @media (max-width: 360px) {
                    .rdrCalendarWrapper .rdrMonths {
                      font-size: smaller !important;
                    }
                  }
               `}
            </style>
          </Flex>
        </InputGroup>
      </FormControl>
    </>
  )
}

export default TravelDestinationAndDate;