// src/components/Footer.jsx

import React from 'react';
import { Box, Text, useColorMode, useTheme } from '@chakra-ui/react';

const Footer = () => {
  const { colorMode } = useColorMode();
  const theme = useTheme();

  return (
    <Box
      bg={colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg}
      h='60px'
      position="relative"
      zIndex={4}
      p={4} color='white'
      textAlign='center'
      justifyContent="center">
      <Text
        textAlign='center'
        color={colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color} >
        © 2024 Very Good Trip. All rights reserved.</Text>
    </Box>
  );
};

export default Footer;
