import React from 'react';
import {
  Button, Wrap, WrapItem,
  useColorMode,
  useTheme,
} from '@chakra-ui/react';

const travelInterests = [
  { label: 'Randonnée', icon: '🥾' },
  { label: 'Culture', icon: '🏛️' },
  { label: 'Plage', icon: '🏖️' },
  { label: 'Aventure', icon: '🚵' },
  { label: 'Gastronomie', icon: '🍽️' },
  { label: 'Histoire', icon: '📜' },
  { label: 'Nature', icon: '🌿' },
  { label: 'Relaxation', icon: '🧘' },
  { label: 'Sport', icon: '⚽' },
  { label: 'Shopping', icon: '🛍️' },
  { label: 'Vie Nocturne', icon: '🌃' },
  { label: 'Bien-être', icon: '💆' },
  { label: 'Photographie', icon: '📸' },
  { label: 'Exploration Urbaine', icon: '🚶' },
  { label: 'Art', icon: '🎨' },
  { label: 'Musique', icon: '🎵' },
  { label: 'Festival', icon: '🎉' },
  { label: 'Architecture', icon: '🏗️' },
  { label: 'Croisière', icon: '🚢' },
  { label: 'Parc à Thèmes', icon: '🎢' },
  { label: 'Observation de la Faune', icon: '🦁' },
  { label: 'Escalade', icon: '🧗' },
  { label: 'Sports Nautiques', icon: '🌊' },
  { label: 'Spa et Détente', icon: '🛀' },
  { label: 'Patrimoine Mondial', icon: '🏰' },
  { label: 'Activités en Famille', icon: '👨‍👩‍👧‍👦' },
  { label: 'Écotourisme', icon: '🌍' },
  { label: 'Road Trip', icon: '🚗' },
  { label: 'Équitation', icon: '🐎' },
  { label: 'Camps et Glamping', icon: '⛺' }
];

const TravelInterestsButtons = ({ selectedInterests, onSelectInterest }) => {
  const { colorMode } = useColorMode();
  const theme = useTheme();
  return (
    <Wrap spacing="15px" justify="center">
      {travelInterests.map((interest, index) => (
        <WrapItem key={index}
          bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white}
          color={colorMode === 'dark' ? theme.colors.dark_bg.white : theme.colors.light_bg.black}
        >
          <Button
            leftIcon={<span>{interest.icon}</span>}
            colorScheme={selectedInterests.includes(interest.label) ? "teal" : "gray"}
            variant="outline"
            size={{ base: 'xs', md: 'md' }}
            onClick={() => onSelectInterest(interest.label)}
          >
            {interest.label}
          </Button>
        </WrapItem>
      ))}
    </Wrap>
  );
};

export default TravelInterestsButtons;
