import { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Textarea,
  Heading,
  SimpleGrid,
  Box,
  useColorMode,
  useTheme,
} from '@chakra-ui/react';
import TravelInterestsButtons from './TravelInterestsButtons';

// Composant SocialMediaForm
const TravelPreferenceForm = ({ formData, handleChange }) => {

  const { colorMode } = useColorMode();
  const theme = useTheme();

  const [selectedInterests, setSelectedInterests] = useState([]);

  const handleSelectInterest = (interest) => {
    const updatedInterests = selectedInterests.includes(interest)
      ? selectedInterests.filter(i => i !== interest)
      : [...selectedInterests, interest];

    setSelectedInterests(updatedInterests);
    handleChange('interests', updatedInterests);
  };

  return (
    <Box>
      <Heading w="100%" textAlign={'center'} fontWeight="normal" mb="2%">
        Intérêt
      </Heading>
      <SimpleGrid columns={1} spacing={6}>
        <TravelInterestsButtons selectedInterests={selectedInterests} onSelectInterest={handleSelectInterest} />
        <FormControl id="preference" mt={1}>
          <FormLabel
            fontSize={{ sm: 'sm', md: 'md' }}
            fontWeight="md"
            mt={2}
            ml={1}
          >
            Description de vos préférences de voyages
          </FormLabel>
          <Textarea
            bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white}
            color={colorMode === 'dark' ? theme.colors.dark_bg.white : theme.colors.light_bg.black}
            placeholder="Je souhaite découvrir la gastronomie de la ville, je veux être chill au bord de la plage etc ..."
            rows={4}
            resize={'none'}
            shadow="sm"
            focusBorderColor="brand.400"
            fontSize={{ sm: 'sm', md: 'md' }}
            value={formData.preference || ''}
            onChange={(e) => handleChange('preference', e.target.value)}
          />
        </FormControl>
      </SimpleGrid>
    </Box>
  );
};

export default TravelPreferenceForm;
