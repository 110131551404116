import React from 'react';
import { Box, Image, Icon, useColorMode, useTheme, keyframes } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';


const images = [
    require("../../imageComponents/SliderAccueil/îleParadisiaque0.webp"),
    require("../../imageComponents/SliderAccueil/îleParadisiaque1.webp"),
    require("../../imageComponents/SliderAccueil/îleParadisiaque2.webp")
];

function ImageSlider() {
    const { colorMode } = useColorMode();
    const theme = useTheme();

    // Définir l'animation pour révéler le texte de gauche à droite
    const revealAnimationVeryGoodTrip = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

    // Définir l'animation pour révéler le texte de gauche à droite
    const revealAnimationSlogan = keyframes`
 0% {
   opacity: 0;
 }
 50% {
   opacity: 0;
 }
 100% {
   opacity: 1;
 }
`;

    // Définir l'animation de rebond pour la flèche
    const bounceAnimation = keyframes`
 0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
 40% { transform: translateY(-10px); }
 60% { transform: translateY(-5px); }
`;

    return (
        <Box width="100vw" height="calc(100vh - 60px)" // height= 100vh - height Navbar
            position="relative" alignContent="center" overflow="hidden">
            <style>
                {`
                    .slide0 {
                        animation: fade0 20s ease-in-out ;
                    }
                    .slide1 {
                        animation: fade1 20s ease-in-out ;
                    }
                    .slide2 {
                        animation: fade2 20s ease-in-out ;
                    }

                    @keyframes fade0 {
                        0% { 
                            opacity: 1; 
                        }
                        30% { 
                            opacity: 1; 
                            transform: scale(1.1); 
                        }
                        40%, 100% { 
                            opacity: 0; 
                            transform: scale(1.1); 
                        }
                        100% { 
                            opacity: 0; 
                            transform: scale(1); 
                        }
                    }
                    @keyframes fade1 {
                        0%, 20% { 
                            opacity: 0; 
                            transform: scale(1); 
                        }
                        30% { 
                            opacity: 1; 
                        }
                        60% { 
                            opacity: 1; 
                            transform: scale(1.1); 
                        }    
                        70% { 
                            opacity: 0; 
                            transform: scale(1.1); 
                        }
                        100% { 
                            opacity: 0; 
                            transform: scale(1); 
                        }
                    }
                    @keyframes fade2 {
                        0% { 
                            opacity: 0; 
                            transform: scale(1); 
                        }
                        10% { 
                            opacity: 0; 
                            transform: scale(1.1); 
                        }
                        50% { 
                            opacity: 0; 
                            transform: scale(1); 
                        }
                        60% { 
                            opacity: 1; 
                        }
                        100% { 
                            opacity: 1; 
                            transform: scale(1); 
                        }
                    }
                `}
            </style>

            {images.map((url, index) => (
                <Image
                    key={index}
                    src={url}
                    alt={`Slide ${index}`}
                    position="absolute"
                    top="0"
                    width="100vw"
                    height="100vh"
                    objectFit="cover"
                    filter={colorMode === 'dark' ? 'brightness(0.4)' : 'brightness(0.8)'}
                    className={`slide${index}`} // Assign slide1, slide2, slide3 based on index
                />
            ))}

            <Box textAlign="center" fontSize={{ base: "50px", md: "100px" }} position="relative" zIndex={2}>
                <Box
                    as="h2"
                    color={theme.colors.light_tertiary.color}
                    textShadow="2px 2px 20px rgba(0, 0, 0, 0.5)"
                    fontFamily="sans-serif"
                    display="inline"
                    animation={`${revealAnimationVeryGoodTrip} 3s ease forwards`} // Appliquer l'animation

                >
                    Very Good

                    <Box
                        as="span"
                        pl="3"
                        color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                    >
                        Trip
                    </Box>
                </Box>
                <Box textAlign="center" fontSize={{ base: "20px", md: "40px" }}
                    textShadow="2px 2px 30px rgba(0, 0, 0, 0.5)">
                    <Box as="h3" color={theme.colors.light_tertiary.color}

                        animation={`${revealAnimationSlogan} 3s ease forwards`} // Appliquer l'animation

                    >
                        Votre générateur de voyages
                    </Box>
                </Box>
            </Box>

            {/* Flèche animée vers le bas */}
            <Box
                position="absolute"
                bottom="20px"
                left={{ base: "45%", md: "48%" }}
                transform="translateX(-50%)"
                zIndex={2}
                animation={`${bounceAnimation} 2s infinite`}
                cursor="pointer"
                onClick={() => document.getElementById('multistep-section').scrollIntoView({ behavior: 'smooth' })}
            >
                <Icon as={ChevronDownIcon} boxSize="50px" color="white" />
            </Box>
        </Box>
    );
}

export default ImageSlider;
