import { Grid, GridItem, Box, Divider, useColorMode, useTheme } from '@chakra-ui/react';
import HomeNavbar from '../common/HomeNavbar';
import Footer from '../common/Footer';
import SliderAccueil from '../sections/Sliders/SliderAccueil';
import NewsletterSignup from '../forms/NewsLetterSignup';
import MultistepFormsExample from '../sections/multiStepFormsExample';

export default function TravelHome() {
  const { colorMode } = useColorMode();
  const theme = useTheme();
  return (
    <Grid
      templateAreas={
        `"header header"
        "main main"
         "footer footer"` }
      gridTemplateRows={'auto auto 60px'}
      gridTemplateColumns='1fr'
      h='auto'
      color='blackAlpha.700'
      fontWeight='bold'
    >

      <GridItem area={'header'}>
        <HomeNavbar />
      </GridItem>

      <GridItem area={'main'}
        bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.lightgrey}
        overflowX="hidden"
        overflowY="hidden">
        <SliderAccueil />
        <Divider h="2px"></Divider>
        <Box
          id="multistep-section"
          position="relative"
          py={{ base: "50px", md: "130px" }}
          bgImage="url('../images/Patagonie.jpg')"
          bgSize="cover"
          bgPosition="center"
          bgRepeat="no-repeat"

        >
          {/* Superposition semi-transparente */}
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg={colorMode === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.1)'}
            zIndex="1"
          />

          {/* Contenu principal */}
          <Box position="relative" zIndex="2" padding={5} >
            <MultistepFormsExample />
          </Box>
        </Box>
        <NewsletterSignup />
      </GridItem>

      <GridItem area={'footer'}>
        <Footer />
      </GridItem>

    </Grid>
  );
};